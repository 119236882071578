import {
  AchDonationWorkflowStep,
  CreditCardDonationWorkflowStep,
  CryptoDonationWorkflowStep,
  DafDonationWorkflowStep,
  StockDonationWorkflowStep,
} from 'types/workflow';

export const MINIMAL_USD_VALUES = {
  FIAT: 10,
  CRYPTO: 0,
  STOCK: 5000,
};

export const DEFAULT_USD_VALUES = {
  FIAT: 200,
  CRYPTO: 10000,
};

export const MINIMAL_STOCKS_QUANTITY = 0;
export const CRYPTO_DECIMALS_NUMBER = 8;

export const ACH_STEPS = [
  {
    title: 'Pledge',
    key: AchDonationWorkflowStep.Pledge,
  },
  {
    title: 'Info',
    key: AchDonationWorkflowStep.FillDonorInfo,
  },
  {
    title: 'ACH Info',
    key: AchDonationWorkflowStep.Mandate,
  },
  {
    title: 'Thank you',
    key: AchDonationWorkflowStep.Success,
  },
];

export const CREDIT_CARD_STEPS = [
  {
    title: 'Pledge',
    key: CreditCardDonationWorkflowStep.Pledge,
  },
  {
    title: 'Info',
    key: CreditCardDonationWorkflowStep.FillDonorInfo,
  },
  {
    title: 'Card Info',
    key: CreditCardDonationWorkflowStep.FillCardInfo,
  },
  {
    title: 'Thank you',
    key: CreditCardDonationWorkflowStep.Success,
  },
];

export const CRYPTO_STEPS = [
  {
    title: 'Pledge',
    key: CryptoDonationWorkflowStep.Pledge,
  },
  {
    title: 'Info',
    key: CryptoDonationWorkflowStep.FillDonorInfo,
  },
  {
    title: 'Donate',
    key: CryptoDonationWorkflowStep.Donate,
  },
];

export const STOCKS_STEPS = [
  {
    title: 'Pledge',
    key: StockDonationWorkflowStep.Pledge,
  },
  {
    title: 'Donor Info',
    key: StockDonationWorkflowStep.FillDonorInfo,
  },
  {
    title: 'Broker Info',
    key: StockDonationWorkflowStep.FillBrokerInfo,
  },
];

export const DAF_STEPS = [
  {
    title: 'DAF',
    key: DafDonationWorkflowStep.Pledge,
  },
  {
    title: 'Donate',
    key: DafDonationWorkflowStep.Donate,
  },
  {
    title: 'Thank you',
    key: DafDonationWorkflowStep.Success,
  },
];

export const GENERIC_STEPS = [];
