import { environmentConfig } from 'environment/environment.config';

import geminiLogo from '../assets/images/gemini-logo.svg';
import mainLogo from '../assets/images/main-logo.svg';
import shift4Logo from '../assets/images/shift4-logo.svg';
import { PoweredByBrand } from './types';

export const KEYS = {
  LINK: {
    [PoweredByBrand.Crypto]: environmentConfig.marketingWebsite,
    [PoweredByBrand.Card]: 'https://www.shift4.com/',
  },
  LOGO: {
    [PoweredByBrand.Crypto]: mainLogo,
    [PoweredByBrand.Card]: shift4Logo,
    [PoweredByBrand.Gemini]: geminiLogo,
  },
  ELEMENTS_PER_ROW_LONG: 2,
  ELEMENTS_PER_ROW_SHORT: 3,
  SHORT_ITEMS_REQURED_LENGTH: 5,
  ROW_ITEM_SHORT: 4,
  ROW_ITEM_LONG: 6,
  LINK_SUPPORT_FROM_DONORS: environmentConfig.supportLinkFromDonors,
  DEFAULT_SUPPORT_EMAIL: environmentConfig.defaultSupportEmail,
  MAX_SYMBOLS: 30,
  TRIM_TO_SYMBOLS: 25,
};

export const LABELS = {
  ALT: {
    [PoweredByBrand.Crypto]: 'Crypto Donation Provider',
    [PoweredByBrand.Card]: 'Card Donation Provider',
  },
  POWERED_BY: 'Powered by',
  HELP_MESSAGE: 'Help',
  CONTACT_US: 'Contact Us',
  DONOR_SUPPORT: 'Donor Support',
  CLICK_HERE: 'Click Here',
  COPIED: 'Copied',
};
