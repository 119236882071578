import { connect, MapDispatchToPropsParam, MapStateToPropsParam } from 'react-redux';
import { Dispatch } from 'redux';

import { AppReduxState } from 'state/state';
import signStockDonationPledge from 'state/stock/thunk/signStockDonationPledge.thunk';
import updateSignature from 'state/stock/updateSignature';
import changeStep from 'state/workflow/changeStep';
import { StockDonationWorkflowStep } from 'types/workflow';
import componentSwitch from 'utils/componentSwitch';

import { SigningPage as OverlaySigningPage } from './overlayVariant/signing';
import { SigningPage } from './signing';

const mapStateToProps: MapStateToPropsParam<any, any, AppReduxState> = (state) => {
  const { donationUuid } = state.donation.stocks;
  const { signature, submitting } = state.stock;
  const { organization } = state.organization;
  const grantProviderName = organization?.grantProviderOptions?.stock?.grantProviderName ?? null;

  return {
    donationUuid,
    signature,
    submitting,
    isSubmitDisabled: !signature,
    grantProviderName,
  };
};

const mapDispatchToProps: MapDispatchToPropsParam<any, any> = (dispatch: Dispatch<any>) => ({
  goBack: () => dispatch(changeStep.createAction(StockDonationWorkflowStep.FillBrokerInfo)),
  updateSignature: (signature: string) => dispatch(updateSignature.createAction(signature)),
  signDonationPledge: async (donationUuid: string, signature: string) => dispatch(signStockDonationPledge(donationUuid, signature)),
});

const mergeProps = (stateProps, dispatchProps) => {
  const signDonationPledge = () => dispatchProps.signDonationPledge(stateProps.donationUuid, stateProps.signature);

  return {
    ...stateProps,
    ...dispatchProps,
    signDonationPledge,
  };
};

export const SigningPledgeConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(componentSwitch(SigningPage, OverlaySigningPage));

export default SigningPledgeConnect;
