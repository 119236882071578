export const prepareLine = (line: string, replacements: Record<string, string>): string => {
  if (!replacements) {
    return line;
  }

  return Object.entries(replacements)
    .reduce((
      output,
      [key, value],
    ) => {
      if (!value) {
        return output;
      }

      return output.replaceAll(key, value);
    }, line);
};

export const capitalizeFirstLetter = (message: string): string => {
  const firstLetter = message[0].toUpperCase();
  const restOfMessage = message.substring(1, message.length);

  return `${firstLetter}${restOfMessage}`;
};

export default prepareLine;
