import { Dispatch } from 'redux';

import { API, Endpoints } from 'api';
import { CreditCardReduxState } from 'state/creditCard/state';
import { CardDonationReduxState } from 'state/donation/state';
import changeStep from 'state/workflow/changeStep';
import { ApplePayEventAction, ApplePayTransactionStatus, WIDGET_CHANNEL } from 'types/applePay';
import Frequency from 'types/frequency';
import { CreditCardDonationWorkflowStep } from 'types/workflow';
import { getExpirationDate } from 'utils/card';
import { removeNullValues } from 'utils/objects/removeNullValues';

import payFail from '../pay.fail';
import payStart from '../pay.start';
import paySuccess from '../pay.success';

export const DEFAULT_ERROR_MESSAGE = 'Payment could not be processed. Please try again or contact support@thegivingblock.com. RequestId:';
const KEYS = {
  UNSPECIFIED_ORIGIN: '*',
};

export interface ChargeParams {
  serverInfo: CardDonationReduxState;
  creditCardInfo: CreditCardReduxState;
  captchaToken: string | null;
  frequency?: Frequency;
  widgetId?: string;
}

export const prepareBody = ({
  serverInfo,
  creditCardInfo,
  captchaToken,
  frequency,
}: ChargeParams) => {
  const { cardExpirationDate } = creditCardInfo;
  const { expirationMonth, expirationYear } = getExpirationDate(cardExpirationDate);
  const recurring = frequency === Frequency.Once ? null : frequency;

  return removeNullValues({
    donationUuid: serverInfo.donationUuid,
    cardToken: serverInfo.cardToken,
    cardType: serverInfo.brand,
    method: serverInfo.method,
    expirationMonth: Number(expirationMonth),
    expirationYear: Number(expirationYear),
    cardLast4Numbers: creditCardInfo.cardNumber.substring(creditCardInfo.cardNumber.length - 4) || null,
    remember: false,
    captchaToken,
    recurring,
  });
};

export const pay = ({
  serverInfo,
  creditCardInfo,
  captchaToken,
  frequency,
  widgetId,
}: ChargeParams) => async (dispatch: Dispatch<any>) => {
  dispatch(payStart.createAction());

  try {
    const body = prepareBody({
      serverInfo, creditCardInfo, captchaToken, frequency,
    });
    const response = await API.post(Endpoints.donationFiatCharge, body);

    if (response?.data?.isSuccess) {
      dispatch(paySuccess.createAction());
      dispatch(changeStep.createAction(CreditCardDonationWorkflowStep.Success));
      window.parent.postMessage({
        channel: WIDGET_CHANNEL,
        action: ApplePayEventAction.applePayChargeResponse,
        status: ApplePayTransactionStatus.Success,
        widgetId,
      }, KEYS.UNSPECIFIED_ORIGIN);

      return;
    }

    throw [DEFAULT_ERROR_MESSAGE, response.requestId].join(' ');
  } catch (e: any) {
    const { requestId } = e.details;
    window.parent.postMessage({
      channel: WIDGET_CHANNEL,
      action: ApplePayEventAction.applePayChargeResponse,
      status: ApplePayTransactionStatus.Fail,
      widgetId,
    }, KEYS.UNSPECIFIED_ORIGIN);

    const errorMessage = [DEFAULT_ERROR_MESSAGE, requestId].join(' ');
    dispatch(payFail.createAction(errorMessage));
  }
};

export default pay;
